<template lang="html">
  <section class="accessibility">
    <div class="container">
      <template v-if="entry">
        <div @click="goToBack()" class="btn-back" title="Volver a la página anterior">Volver</div>
        <h1 class="title-cat-big">{{entry.name}}</h1>
        <EditorJs :object="entry.body" />
      </template>
    </div>
  </section>
</template>

<script lang="js">
  import store from '@/store';
  import {
    mapGetters,
    mapActions
  } from 'vuex';
  import EditorJs from "@/components/editorJs.vue";
  export default {
    name: 'accessibility',
    props: [],
    components: {
      EditorJs
    },
    mounted() {
      this.loadPageFixed({
        slug: this.$route.path.replace('/', '')
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapActions({
        loadPageFixed: 'contents/loadPageFixed',
      }),
      goToBack() {
        this.$router.back()
      }
    },
    computed: {
      entry() {
        return store.getters['contents/getPageFixed'](this.$route.path.replace('/', ''))
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../styles/colours.scss";

  .accessibility {
    .title-cat-big {
      border-top: 0;
      margin-bottom: 20px;
    }

    h1 {
      text-align: center;
    }

    a,
    p {
      margin-bottom: 20px;
      font-size: 17px;

      &.title-dest-underline {
        font-size: 25px;
      }
    }

    a {
      color: $blue;
      font-weight: 700;
      text-decoration: none;
    }

    ul {
      padding-left: 20px;
      margin-bottom: 20px;

      li {

        font-size: 17px;
        margin-bottom: 15px;

        strong {
          font-weight: bold;

        }
      }
    }

    .link-mail {
      font-family: "Frank Ruhl Libre", serif;
    }

    .link-phone {
      color: #000000;
    }
  }
</style>